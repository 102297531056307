/*
  eslint-disable
  @typescript-eslint/no-unsafe-member-access,
  @typescript-eslint/no-unsafe-call
*/
import { useState, useEffect } from 'react';
import {
  chakra,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  SimpleGrid,
  Box,
  Container,
  Input,
  Switch,
  Text,
  Select,
  Button,
  FormControl,
  FormErrorMessage,
  Collapse,
  Flex,
  useConst,
  useToast,
  UseToastOptions,
  Grid,
  FormLabel,
} from '@chakra-ui/react';
import { Editor } from 'wisipoo';
import { Analytics } from 'cliengo-fe-utils';
import { useSearchParams } from 'react-router-dom';

import useTrigger from '../../hooks/useTrigger';
import { IMatch } from '../../utils/interfaces';
import QuestionsHeader from '../questionsHeader';
import { ActionButton } from '../ActionButton/ActionButton.component';

import { ITriggerProps } from './trigger.types';

import InputSelect from '@/components/InputSelect';
import TypeResponse from '@/components/TypeResponse';
import ActionsResponse from '@/components/ActionsResponse';
import InputControl from '@/components/InputControl';
import hasSmartTriggers from '@/utils/helpers/hasSmartTriggers';
import {
  StyledEditor,
  StyledEditorErrorWrapper,
} from '@/components/Editor/Editor.styles';
import { validateMatchValue } from '@/utils/validators/matchValidators';
import { isEditorEmpty } from '@/utils/validators/editorValidators';
import { requiredFieldsValidator } from '@/utils/validators/triggerValidators';
import { translate } from '@/utils/i18n/translate';
import {
  TypesEvent,
  TypesFeedbackContent,
} from '@/utils/feedback/feedback.types';
import feedbackContents from '@/utils/feedback/feedbackContents';
import {
  MATCH_VALUE_CHAR_LIMIT,
  MATCH_VALUE_CHAR_MINIMUM,
} from '@/utils/constants';
import { Alert } from '@/ui/Alert/Alert.component';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux/redux';
import { smartSuggestionsActions } from '@/store/smart-suggestions/smart-suggestions.slice';

const Trigger = ({
  index,
  trigger,
  usersList,
  editorType,
  companyType,
  show,
  hasControls,
  accountLabs,
  onToggleVisivility,
  onResetVisivility,
  onDone,
  onDelete,
  onDiscard,
  isNewTrigger,
}: ITriggerProps): JSX.Element => {
  const {
    triggerState,
    handleUpdateName,
    handleUpdateEnable,
    handleUpdateResponse,
    handleUpdateTypeResponse,
    handleUpdateIgnoreLead,
    handleUpdateStopNextMessage,
    handleAddNewMatch,
    handleDeleteMatch,
    handleUpdateMatchValue,
    handleUpdateMatchType,
    handleUpdateAssignedToAdd,
    handleUpdateAssignedToRemove,
    handleAddNewOption,
    handleRemoveOption,
    handleResetOptions,
    handleSetSaved,
    handleResetTrigger,
  } = useTrigger(trigger);
  const [searchParams] = useSearchParams();
  const triggerMatchesQuantity = triggerState.matches.length;
  const canIDeleteMatches = triggerMatchesQuantity <= 1;
  const dispatch = useAppDispatch();
  const { labs } = useAppSelector((state) => state.account);
  const { availableSmartTriggers } = useAppSelector((state) => state.website);
  const validationMessages = useConst(() => ({
    typeResponse: {
      noEmpty: translate('lead.validation_messages.typeResponse.noEmpty'),
      isDuplicated: translate(
        'lead.validation_messages.typeResponse.isDuplicated'
      ),
    },
    matchs: {
      noEmpty: translate('lead.validation_messages.matchs.noEmpty'),
      tooLong: translate('lead.validation_messages.matchs.tooLong'),
      tooShort: translate('lead.validation_messages.matchs.tooShort'),
    },
  }));

  const [hasError, setHasError] = useState<boolean>(false);

  const toast = useToast();

  //Due to technical reasons, this event is being triggered this way.
  const handleParticularEvents = () => {
    Analytics.trackEvent({
      eventName: 'view_response',
      category: 'triggers',
      action: 'view_response',
    });
  };

  const handleToggle = () => {
    if (onToggleVisivility) {
      onToggleVisivility(index);
    }
  };

  useEffect(() => {
    const hasError = requiredFieldsValidator(triggerState);
    setHasError(hasError);
  }, [triggerState]);

  const handleOnDiscard = () => {
    handleResetTrigger();
    if (hasSmartTriggers(labs, availableSmartTriggers)) {
      dispatch(smartSuggestionsActions.removeSuggestionSelected());
    }
    if (onDiscard) {
      onDiscard();
    }
  };

  const handleOnDelete = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete(triggerState.id);
      if (onResetVisivility) {
        onResetVisivility();
      }
    }
  };

  const handleOnSave = () => {
    if (hasError) {
      handleNotificationEvent({
        ...(feedbackContents[
          TypesEvent.notificationError
        ] as TypesFeedbackContent),
        title: translate('notificactions.trigger_invalid_fields_error.title'),
        description: translate(
          'notificactions.trigger_invalid_fields_error.description'
        ),
      });
    }

    if (!hasError && !hasControls) {
      handleResetTrigger();
    }

    if (triggerState.hasUnsaveChanges && !hasError) {
      if (triggerState.typeResponse === 'text') {
        handleResetOptions();
      }

      onDone({
        ...triggerState,
        responseOptions:
          triggerState.typeResponse === 'text'
            ? []
            : triggerState.responseOptions,
      });
      handleSetSaved();
    }
  };

  const handleNotificationEvent = (feedback: TypesFeedbackContent): void => {
    toast(feedback as UseToastOptions);
  };

  const isEmbeded = searchParams.toString().includes('cocos');

  return (
    <div>
      <Box
        bg={'white'}
        rounded="xl"
        my="4"
        p="4"
        boxShadow={triggerState.enabled ? 'sm' : 'none'}
      >
        <SimpleGrid columns={1}>
          <Flex onClick={handleToggle} w={'100%'} alignItems="center">
            {show ? (
              <Input
                data-testid="answer_response"
                placeholder={translate('lead.trigger.name')}
                defaultValue={
                  triggerState.name
                    ? triggerState.name
                    : companyType === 'lead'
                    ? translate('lead.dataTrigger.answer_response')
                    : translate('convex.dataTrigger.answer_keyword')
                }
                onClick={(e) => e.stopPropagation()}
                onChange={handleUpdateName}
                color={triggerState.enabled ? '#515151' : '#b0b0b0'}
                fontWeight={'bold'}
                borderRadius={'6px'}
                textAlign={'left'}
                w={'100%'}
                borderColor={triggerState.enabled ? '#b0b0b0' : '#e6e6e6'}
                border={'1px solid'}
                focusBorderColor={'none'}
                variant="outline"
                _hover={{ borderColor: '#B0B0B0;' }}
              />
            ) : (
              <Text
                color={triggerState.enabled ? '#515151' : '#b0b0b0'}
                fontWeight={'bold'}
                w={'100%'}
                paddingX={'16px'}
              >
                {triggerState.name
                  ? triggerState.name
                  : companyType === 'lead'
                  ? translate('lead.dataTrigger.answer_response')
                  : translate('convex.dataTrigger.answer_keyword')}
                {triggerState.hasUnsaveChanges && <span> *</span>}
              </Text>
            )}
            {hasControls && (
              <Flex align={'center'}>
                {show && (
                  <Container onClick={(e) => e.stopPropagation()}>
                    <Box>
                      <FormLabel
                        data-testid="enabled-trigger-switch"
                        htmlFor="enabled-trigger-switch"
                        hidden
                      >
                        switch enabled trigger
                      </FormLabel>
                      <Switch
                        id="enabled-trigger-switch"
                        colorScheme="secondary"
                        float={'right'}
                        onChange={handleUpdateEnable}
                        isChecked={triggerState.enabled}
                      />
                    </Box>
                  </Container>
                )}
                <ActionButton
                  onClick={handleParticularEvents}
                  data-testid="toggle-visivility"
                  icon={`icon-c${show ? 'up' : 'down'}`}
                />
                <Box>
                  <Menu>
                    <MenuButton p="3" onClick={(e) => e.stopPropagation()}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#515151"
                        />
                      </svg>
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={handleOnDelete}>
                        {translate('delete_trigger')}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
            )}
          </Flex>
        </SimpleGrid>
        <Collapse in={show}>
          <Box marginTop={'30px'} pb={4}>
            {isEmbeded && isNewTrigger && (
              <Alert
                closable
                closableId="triggers-info"
                trackCloseEvent={() =>
                  Analytics.trackEvent({
                    action: 'click_hide_triggers_info',
                    eventName: 'click_hide_triggers_info',
                    category: 'conversation',
                  })
                }
                closableExpTime={{
                  number: 15,
                  unit: 'days',
                }}
                my={4}
                color="warning"
                text={translate('info')}
              />
            )}
            <QuestionsHeader companyType={companyType ?? 'lead'} />
            {triggerState.matches &&
              triggerState.matches.map((elem: IMatch, i: number) => (
                <Grid
                  templateColumns={'1fr 1fr'}
                  key={`match-${trigger?.id as string}-${i}`}
                >
                  <InputControl
                    configProps={{
                      id: `match-value-${trigger?.id as string}-index-${i}`,
                      value: elem.value,
                      onChange: (value: string) => {
                        handleUpdateMatchValue(value, i, elem.type);
                      },
                      onError: (value: string) =>
                        validateMatchValue(value, validationMessages.matchs),
                      onBlur: (value: string) =>
                        validateMatchValue(value, validationMessages.matchs),
                      maxLength: MATCH_VALUE_CHAR_LIMIT,
                      minLength: MATCH_VALUE_CHAR_MINIMUM,
                      autoComplete: 'off',
                      disabled: false,
                      texts: {
                        placeholder: translate('convex.dataTrigger.how_to_ask'),
                      },
                      'data-testid': `match-value-${
                        trigger?.id as string
                      }-index-${i}`,
                    }}
                  />
                  <Grid
                    mb="4"
                    templateColumns="1fr 16px"
                    width={{
                      base: '100%',
                      lg: 'calc(100% - 2rem)',
                    }}
                    alignItems="center"
                  >
                    <Select
                      data-testid="matching_type_options"
                      name="type"
                      borderColor={'#B0B0B0;'}
                      border={'1px solid'}
                      fontSize={{
                        base: '12px',
                        lg: '16px',
                      }}
                      ml={{
                        base: 4,
                        lg: '28px',
                      }}
                      focusBorderColor={'none'}
                      width="calc(100% - 2rem)"
                      variant="outline"
                      value={elem.type}
                      onChange={(e) => handleUpdateMatchType(e, i, elem.value)}
                      _hover={{ borderColor: '#B0B0B0;' }}
                    >
                      <option data-testid="equals" value="EQUALS">
                        {translate('convex.matching_type_options.equals')}
                      </option>
                      <option data-testid="contains" value="CONTAINS">
                        {translate('convex.matching_type_options.contains')}
                      </option>
                      <option data-testid="phrase" value="PHRASE">
                        {translate('convex.matching_type_options.smarts')}
                      </option>
                    </Select>
                    <Box
                      px={{
                        base: 0,
                        lg: 4,
                      }}
                      as="button"
                      onClick={() => handleDeleteMatch(i)}
                      disabled={canIDeleteMatches}
                    >
                      <chakra.i
                        color={canIDeleteMatches ? '#707070' : '#515151'}
                        fontSize={{
                          base: '16px',
                          lg: '20px',
                        }}
                        className="icon icon-delete"
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            <Box>
              <Text
                data-testid="add_another_ask"
                as={'a'}
                cursor={'pointer'}
                fontWeight={'400'}
                color="#1ABC9C"
                onClick={handleAddNewMatch}
              >
                <Text as={'span'} mr={'5px'}>
                  <i className="icon icon-add" />{' '}
                </Text>
                {translate('add_another_ask')}
              </Text>
            </Box>
            <Grid
              templateColumns={{
                base: '1fr',
                lg: '1fr 1fr',
              }}
              gap={10}
              marginTop={'15px'}
            >
              <Box>
                <Text
                  data-testid="bot_will_reply"
                  fontSize={{
                    base: '14px',
                    lg: '16px',
                  }}
                  marginBottom={'15px'}
                  fontWeight={'bold'}
                >
                  {translate('bot_will_reply')}
                </Text>
                <div style={{ marginBottom: '15px' }}>
                  <FormControl isInvalid={isEditorEmpty(triggerState.response)}>
                    <StyledEditor data-testid="editor">
                      <Editor
                        placeholder={
                          companyType === 'lead'
                            ? translate('lead.editor_placeholder')
                            : translate('convex.editor_placeholder')
                        }
                        onChange={handleUpdateResponse}
                        initialValue={triggerState.response}
                        editMode={true}
                        handleEditMode={() => false}
                        websiteType={editorType}
                      />
                    </StyledEditor>
                    <StyledEditorErrorWrapper>
                      {isEditorEmpty(triggerState.response) && (
                        <FormErrorMessage mt={'0'}>
                          {companyType === 'lead'
                            ? translate('lead.editor_error_message')
                            : translate('convex.editor_error_message')}
                        </FormErrorMessage>
                      )}
                    </StyledEditorErrorWrapper>
                  </FormControl>
                </div>
                <Text
                  data-testid="response_actions"
                  fontSize={{
                    base: '14px',
                    lg: '16px',
                  }}
                  mt={'10px'}
                  fontWeight={'bold'}
                >
                  {translate('response_actions')}
                </Text>
                <ActionsResponse
                  hasStopNextMessage={triggerState.stopNextMessage}
                  typeResponse={triggerState.typeResponse ?? 'text'}
                  hasIgnoreLead={triggerState.ignoreLead}
                  onUpdateStopNextMessage={handleUpdateStopNextMessage}
                  onUpdateIgnoreLead={handleUpdateIgnoreLead}
                />
              </Box>
              <Box
                borderLeft={{
                  base: 'none',
                  lg: '1px solid #C4C4C4',
                }}
              >
                <Box
                  marginLeft={{
                    base: 0,
                    lg: '15px',
                  }}
                >
                  <Text
                    data-testid="user_assignment"
                    fontSize={{
                      base: '14px',
                      lg: '16px',
                    }}
                    fontWeight={'bold'}
                    mb={'10px'}
                  >
                    <i className="icon icon-person" />
                    <span style={{ marginLeft: '5px' }}>
                      {translate('user_assignment')}
                    </span>
                  </Text>
                  <InputSelect
                    config={{
                      'data-testid': 'user_assignment_placeholder',
                      id: 'assignation-input',
                      options: usersList || {},
                      texts: {
                        placeholder: translate('user_assignment_placeholder'),
                        helper: '',
                        messageIsError: '',
                      },
                      onSelectAssigned: (userId) => {
                        handleUpdateAssignedToAdd(userId);
                      },
                      onRemoveAssigned: (userId) => {
                        handleUpdateAssignedToRemove(userId);
                      },
                      value: {
                        id: triggerState.assignedTo[0] ?? '',
                      },
                    }}
                  />
                  {/* eslint-disable-next-line */}
                  {!isEmbeded && !accountLabs?.convex && (
                    <TypeResponse
                      triggerId={triggerState.id ?? ''}
                      typeResponse={triggerState.typeResponse}
                      responseOptions={triggerState.responseOptions}
                      companyType={companyType}
                      onAddNewOption={handleAddNewOption}
                      onRemoveOption={handleRemoveOption}
                      onUpdateTypeResponse={handleUpdateTypeResponse}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Flex
              justifyContent="flex-end"
              flexDir={{
                base: 'column',
                lg: 'row',
              }}
              mt={4}
            >
              {!hasControls && (
                <Button
                  data-testid="discard_button"
                  mx={2}
                  w="calc(100% - 1rem)"
                  mb={{
                    base: 2,
                    lg: 0,
                  }}
                  maxW={{
                    base: 'none',
                    lg: '151px',
                  }}
                  borderRadius={'16px'}
                  variant="outlineDanger"
                  filter="grayscale(100%)"
                  onClick={handleOnDiscard}
                >
                  {translate('discard')}
                </Button>
              )}
              <Button
                data-testid="done_button"
                mx={2}
                mb={{
                  base: 2,
                  lg: 0,
                }}
                w="calc(100% - 1rem)"
                maxW={{
                  base: 'none',
                  lg: '151px',
                }}
                borderRadius={'16px'}
                backgroundColor="primary"
                colorScheme="primary.500"
                _hover={{ bg: 'primary.800' }}
                onClick={handleOnSave}
              >
                {translate('done')}
              </Button>
            </Flex>
          </Box>
        </Collapse>
      </Box>
    </div>
  );
};

export default Trigger;
