import { useState, lazy, Suspense, useEffect } from 'react';
import { Box, useToast, UseToastOptions, SlideFade } from '@chakra-ui/react';
import { Analytics } from 'cliengo-fe-utils';

import AddNewTrigger from '@/components/AddNewTrigger';
import { useAppSelector, useAppDispatch } from '@/hooks/useRedux/redux';
import createTriggerAction from '@/store/trigger/actions/createTrigger.action';
import {
  TypesEvent,
  TypesFeedbackContent,
} from '@/utils/feedback/feedback.types';
import feedbackContents from '@/utils/feedback/feedbackContents';
import { translate } from '@/utils/i18n/translate';
import { IAppTrigger } from '@/utils/interfaces';
import { smartSuggestionsSelectors } from '@/store/smart-suggestions/smart-suggestions.slice';
import { triggerModelForApp } from '@/utils/mappers/triggerDtoApp.mapper';
import rejectSmartSuggestionAction from '@/store/smart-suggestions/actions/reject-smart-suggestion.action';
import { IAppSmartSuggestion } from '@/store/smart-suggestions/smart-suggestions.types';

interface ICreateTriggerProps {
  label: string;
}

const CreateTriggerForm = lazy(() => import('@/components/Trigger'));

const CreateTrigger = ({ label }: ICreateTriggerProps): JSX.Element => {
  const { siteId, editorType } = useAppSelector((state) => state.website);
  const { usersList } = useAppSelector((state) => state.user);
  const { labs } = useAppSelector((state) => state.account);
  const { companyType } = useAppSelector((state) => state.chatbot);
  const { selectedSuggestionId } = useAppSelector(
    (state) => state.smartSuggestions
  );
  const selectedSuggestion = useAppSelector((state) =>
    smartSuggestionsSelectors.selectById(state, selectedSuggestionId)
  );

  const [isShow, setIsShow] = useState(false);

  const dispatch = useAppDispatch();

  const toast = useToast();

  useEffect(() => {
    if (selectedSuggestionId !== '') {
      setIsShow(true);
    }
  }, [selectedSuggestionId]);

  const handleAddNewTrigger = (newTrigger: IAppTrigger): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasUnsaveChanges, typeResponse, isSmartTrigger, ...restOfTrigger } =
      newTrigger;
    dispatch(
      createTriggerAction({
        siteId: siteId ?? '',
        dataTrigger: restOfTrigger,
      })
    )
      .unwrap()
      .then(() => {
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationSuccess
          ] as TypesFeedbackContent),
          title: translate('notificactions.trigger_created_success'),
        });
        isSmartTrigger &&
          void dispatch(
            rejectSmartSuggestionAction({
              siteId: siteId ?? '',
              suggestion: selectedSuggestion as IAppSmartSuggestion,
            })
          );
      })
      .catch((error) => {
        handleNotificationEvent({
          ...(feedbackContents[
            TypesEvent.notificationError
          ] as TypesFeedbackContent),
          title: translate('notificactions.trigger_created_error'),
        });
        console.error(error);
      });
    Analytics.trackEvent({
      eventName: 'new_response',
      category: 'triggers',
      action: 'new_trigger',
    });
  };

  const handleNotificationEvent = (feedback: TypesFeedbackContent): void => {
    toast(feedback as UseToastOptions);
  };

  const handleHideDiscard = (): void => {
    setIsShow(false);
  };

  return (
    <>
      {!isShow ? (
        <SlideFade in={!isShow} offsetY="8px">
          <AddNewTrigger label={label} onShowForm={() => setIsShow(true)} />
        </SlideFade>
      ) : (
        <Suspense fallback={<div>suspense...</div>}>
          <SlideFade in={isShow} offsetY="46px">
            <Box
              mx={{
                base: 0,
                lg: 8,
              }}
            >
              <CreateTriggerForm
                isNewTrigger
                accountLabs={labs}
                companyType={companyType}
                show={isShow}
                trigger={
                  selectedSuggestionId === ''
                    ? null
                    : {
                        ...triggerModelForApp,
                        name: selectedSuggestion?.term ?? '',
                        matches: [
                          {
                            value: selectedSuggestion?.term ?? '',
                            type: 'EQUALS',
                          },
                        ],
                        isSmartTrigger: true,
                        hasUnsaveChanges: true,
                      }
                }
                usersList={usersList}
                editorType={editorType}
                hasControls={false}
                onDone={(newTrigger) => {
                  if (newTrigger.name === null) {
                    handleAddNewTrigger({
                      ...newTrigger,
                      name:
                        companyType === 'lead'
                          ? translate('lead.dataTrigger.answer_response')
                          : translate('convex.dataTrigger.answer_keyword '),
                    });
                  } else {
                    handleAddNewTrigger(newTrigger);
                  }
                  setIsShow(false);
                }}
                onDiscard={handleHideDiscard}
              />
            </Box>
          </SlideFade>
        </Suspense>
      )}
    </>
  );
};

export default CreateTrigger;
