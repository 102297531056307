import { WebSiteFormatType } from '@/adapters/websiteDataAdapter';

function getWebsiteFormatType(website: {
  [key: string]: string | undefined;
}): WebSiteFormatType {
  if (website?.wavyAccessToken) return 'whatsapp';
  if (website.type === 'FACEBOOK') return 'facebook';
  if (website.type === 'INSTAGRAM') return 'instagram';
  return 'website';
}

export default getWebsiteFormatType;
